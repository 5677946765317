/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function() {
                // JavaScript to be fired on all pages
            },
            finalize: function() {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function() {
                // JavaScript to be fired on the home page
            },
            finalize: function() {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function() {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function(func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function() {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    //carousel

    $(document).ready(function(){
        $('.owl-carousel').owlCarousel();

    });

    $('.owl-carousel').owlCarousel({
        items:6,
        autoplay:true,
        autoplayTimeout:3000,
        loop:true,
        margin:60,
        responsive:{
            0:{
                items:2
            },
            600:{
                items:2
            },
            768:{
                items:3
            },
            1024:{
                items:6
            }
        }
    })

    //load more
    $(function () {
        $(".service-gallery-block").slice(0, 6).show();
        $("#loadMore").on('click', function (e) {
            e.preventDefault();
            $(".service-gallery-block:hidden").slice(0, 3).slideDown();
            if ($(".service-gallery-block:hidden").length == 0) {
                $("#load").fadeOut('fast');
            }
            $('html,body').animate({
                scrollTop: $(this).offset().top
            }, 1500);
        });
    });

    if ( $(window).width() < 992 )
    {
        _mobile_submenu();
    }

    $(window).resize(function() {

        if ( $(window).width() < 992 )
        {
            _mobile_submenu();
        }

    });

    function _mobile_submenu() {
        $('.menu-item.dropdown > a').click(function(event) {
            event.stopPropagation();
            event.preventDefault();

            var parent = $(this).closest('.menu-item.dropdown');
            var container = $(this).next('ul');

            if ( !$(parent).hasClass('show') )
            {
                $('.menu-item.dropdown').removeClass('show');
                $('.menu-item .dropdown-menu').removeClass('show');
                $(this).parents('.menu-item.dropdown').addClass('show');
                $(container).addClass('show');
            }
            else
            {
                $(parent).removeClass('show');
                $(container).removeClass('show').hide();
            }
        });
    }

    $(function () {
        $('a[href="#search"]').on('click', function(event) {
            event.preventDefault();
            $('#search').addClass('open');
            $('#search > form > input[type="search"]').focus();
        });

        $('#search, #search button.close').on('click keyup', function(event) {
            if (event.target == this || event.target.className == 'close' || event.keyCode == 27) {
                $(this).removeClass('open');
            }
        });

    });

})(jQuery); // Fully reference jQuery after this point.
